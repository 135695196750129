import {} from "selectric";

$(function() {
  $("select").selectric({ disableOnMobile: false, nativeOnMobile: false });
});

function initMap() {
    mapA = new google.maps.Map(document.getElementById("mapA"), {
        center: {
            lat: 53.7183743,
            lng: -2.667708
        },
        zoom: 13,
        mapTypeControl: false,
        disableDefaultUI: true,
        scrollwheel: false,
        styles: mapStyles
    });

    // mapB = new google.maps.Map(document.getElementById("mapB"), {
    //     center: {
    //         lat: 51.4533044,
    //         lng: -0.1766221
    //     },
    //     zoom: 13,
    //     mapTypeControl: false,
    //     disableDefaultUI: true,
    //     scrollwheel: false,
    //     styles: mapStyles
    // });

    let infowindowA = new google.maps.InfoWindow({
        content: contentA
    });

    // let infowindowB = new google.maps.InfoWindow({
    //     content: contentB
    // });

    markerA = new google.maps.Marker({
        position: {
            lat: 53.7183743,
            lng: -2.667708
        },
        map: mapA,
        icon: fpmarker
    });

    // markerB = new google.maps.Marker({
    //     position: {
    //         lat: 51.4533044,
    //         lng: -0.1766221,
    //     },
    //     map: mapB,
    //     icon: fpmarker
    // });

    markerA.addListener("click", function () {
        infowindowA.open(mapA, markerA);
    });

    // markerB.addListener("click", function () {
    //     infowindowB.open(mapB, markerB);
    // });
}

function sizeMap() {
    google.maps.event.trigger(mapA, "resize");
    mapA.setCenter(mapA.getCenter());

    // google.maps.event.trigger(mapB, "resize");
    // mapB.setCenter(mapB.getCenter());
};

let mapA, mapB, markerA, markerB;
let fpmarker = "https://forepoint.co.uk/wp-content/themes/forepoint/fpmarker.png";

let mapStyles = [
  {
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#4a4a4a"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "transit.station.airport",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
];

let contentA =
    "<div id=\"content\">" +
    "<h5>Forepoint Preston</h5>" +
    "<div id=\"bodyContent\">" +
    "Studio 15<br />" +
    "Momentum Business Park<br />" +
    "Momentum Place, Preston<br />" +
    "PR5 6EF<br />" +
    "01772 511001</p>" +
    "<p><a href=\"https://www.google.com/maps/place/Forepoint+Ltd/@53.718171,-2.6697387,17z/data=!4m12!1m6!3m5!1s0x487b720ceda02c93:0x431bd8310944920e!2sForepoint+Ltd!8m2!3d53.7181678!4d-2.66755!3m4!1s0x487b720ceda02c93:0x431bd8310944920e!8m2!3d53.7181678!4d-2.66755\" target=\"_blank\">" +
    "Get directions</a></p>" +
    "</div>" +
    "</div>";

let contentB =
    "<div id=\"content\">" +
    "<h5>Forepoint London</h5>" +
    "<div id=\"bodyContent\">" +
    "Studio 23<br />" +
    "Royal Victoria Patriotic Building<br />" +
    "John Archer Way, London<br />" +
    "SW18 3SX<br />" +
    "020 8874 2872</p>" +
    "<p><a href=\"https://www.google.com/maps/place/Forepoint+Ltd/@51.4533044,-0.1766221,17z/data=!4m8!1m2!2m1!1sStudio+23++Royal+Victoria+Patriotic+Building++John+Archer+Way,+London++SW18+3SX!3m4!1s0x487605929502375f:0xd272876286bba412!8m2!3d51.4531935!4d-0.1745381\" target=\"_blank\">" +
    "Get directions</a></p>" +
    "</div>" +
    "</div>";

let section = $(".contact--form");
let formContainer = $(".form");

// $(".hero-form select").on("selectric-change", function () {
//   console.log("select");
//   if ( $("option:selected", this).val() != "" ) {
//     section.addClass("open");
//     formContainer.find($(".h3 span")).text($("option:selected", this).text());
//     formContainer.find($("#input_1_17")).val($("option:selected", this).text());
//   } else {
//     section.removeClass("open");
//   }
// });


$("button.close-button").on("click", function () {
    section.removeClass("open");
});

$(".address a.fmarker").on("click", function () {
  //console.log($(this).parents('.column').html());
    $(this).parents(".address").find(".flip-card").toggleClass("success");
    return false;
});

$(function() {
    initMap();
});


$(document).on("gform_post_render", function(){
   //alert('gform_post_render')
});

// $(document).on("gform_confirmation_loaded", function(form, id) {
//   if (id != 8 )
//     $("#contactmodal .flip-card").addClass("success");
// });