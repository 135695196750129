import {} from "selectric";

$(function() {
  $("select").selectric({ disableOnMobile: false, nativeOnMobile: false });
});

$(".image--slides").slick({
    // fade:true,
    autoplaySpeed:4000,
    lazyLoad:"progressive",
    speed:600,
    arrows:true,
    dots:false,
    cssEase:"cubic-bezier(0.87, 0.03, 0.41, 0.9)",
    asNavFor: ".text--slides",
    mobileFirst: true,
  });

  $(".text--slides").slick({
    speed:1000,
    arrows:false,
    dots:false,
    fade: true,
  });

$("select").on("selectric-change", function(){
  $("#filter").submit();
});