import slick from "slick-carousel";
import SimpleBar from "simplebar";
import * as bootstrap from "bootstrap";
import PhotoSwipeLightbox from 'photoswipe/lightbox';

$(function() {
	var menu = document.getElementById("navigation");
	//var header = document.querySelector('#sidebar-header')
	var simplebar = new SimpleBar(menu, {
		forceVisible: true
	});

});

$(".toggle").on("click", function() {
	$("html").toggleClass("noscroll");
	$("header.header").toggleClass("is-active-nav");
});

var slideWrapper = $(".media-slider"),
	flyoutSlider = $(".flyout-slider"),
	imgSliderWrapper = $(".image-slider"),
	reviewSlider = $(".review-slider"),
	reviewSliderNav = $(".review-slider-nav"),
	iframes = slideWrapper.find(".embed-player"),
	lazyImages = slideWrapper.find(".slide-image"),
	lazyCounter = 0;

// POST commands to YouTube or Vimeo API
function postMessageToPlayer(player, command) {
	if (player == null || command == null) return;
	player.contentWindow.postMessage(JSON.stringify(command), "*");
}

// When the slide is changing
function playPauseVideo(slick, control) {
	var currentSlide, slideType, startTime, player, video;

	currentSlide = slick.find(".slick-current");
	slideType = currentSlide.find(".item").attr("class").split(" ")[1];
	player = currentSlide.find("iframe").get(0);
	startTime = currentSlide.data("video-start");

	if (slideType === "vimeo") {
		switch (control) {
			case "play":
				if ((startTime != null && startTime > 0) && !currentSlide.hasClass("started")) {
					currentSlide.addClass("started");
					postMessageToPlayer(player, {
						"method": "setCurrentTime",
						"value": startTime
					});
				}
				postMessageToPlayer(player, {
					"method": "play",
					"value": 1
				});
				break;
			case "pause":
				postMessageToPlayer(player, {
					"method": "pause",
					"value": 1
				});
				break;
		}
	} else if (slideType === "youtube") {
		switch (control) {
			case "play":
				postMessageToPlayer(player, {
					"event": "command",
					"func": "mute"
				});
				postMessageToPlayer(player, {
					"event": "command",
					"func": "playVideo"
				});
				break;
			case "pause":
				postMessageToPlayer(player, {
					"event": "command",
					"func": "pauseVideo"
				});
				break;
		}
	} else if (slideType === "video") {
		video = currentSlide.find("video").get(0);
		if (video != null) {
			if (control === "play") {
				video.play();
			} else {
				video.pause();
			}
		}
	}
}

// Resize player
function resizePlayer(iframes, ratio) {
	if (!iframes[0]) return;
	var win = $(".main-slider"),
		width = win.width(),
		playerWidth,
		height = win.height(),
		playerHeight,
		ratio = ratio || 16 / 9;

	iframes.each(function() {
		var current = $(this);
		if (width / ratio < height) {
			playerWidth = Math.ceil(height * ratio);
			current.width(playerWidth).height(height).css({
				left: (width - playerWidth) / 2,
				top: 0
			});
		} else {
			playerHeight = Math.ceil(width / ratio);
			current.width(width).height(playerHeight).css({
				left: 0,
				top: (height - playerHeight) / 2
			});
		}
	});
}

// DOM Ready
$(function() {
	// Initialize
	slideWrapper.on("init", function(slick) {
		slick = $(slick.currentTarget);
		setTimeout(function() {
			playPauseVideo(slick, "play");
		}, 1000);
		resizePlayer(iframes, 16 / 9);
	});
	slideWrapper.on("beforeChange", function(event, slick) {
		slick = $(slick.$slider);
		playPauseVideo(slick, "pause");
	});
	slideWrapper.on("afterChange", function(event, slick) {
		slick = $(slick.$slider);
		playPauseVideo(slick, "play");
	});
	slideWrapper.on("lazyLoaded", function(event, slick, image, imageSource) {
		lazyCounter++;
		if (lazyCounter === lazyImages.length) {
			lazyImages.addClass("show");
			// slideWrapper.slick("slickPlay");
		}
	});

	//start the slider
	slideWrapper.slick({
		// fade:true,
		autoplaySpeed: 4000,
		lazyLoad: "progressive",
		speed: 600,
		arrows: true,
		infinite: true,
		dots: true,
		cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)"
	});

	imgSliderWrapper.slick({
		// fade:true,
		autoplaySpeed: 4000,
		lazyLoad: "progressive",
		speed: 600,
		arrows: true,
		infinite: true,
		dots: true,
		cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)"
	});

	flyoutSlider.slick({
		// fade:true,
		//autoplaySpeed: 4000,
		//lazyLoad: "progressive",
		speed: 600,
		arrows: false,
		infinite: true,
		dots: true,
		cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)"
	});

	$(".quote-slider").slick({
		// fade:true,
		autoplaySpeed: 4000,
		lazyLoad: "progressive",
		speed: 600,
		arrows: true,
		infinite: true,
		adaptiveHeight: false,
		dots: true,
		cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)"
	});

	$(".clientbanner .slides").slick({
		speed: 90000,
		autoplay: true,
		autoplaySpeed: 0,
		cssEase: "linear",
		slidesToShow: 18,
		slidesToScroll: 18,
		variableWidth: true,
		pauseOnHover: true,
		pauseOnFocus: true,
		infinite: true,
		dots: false,
		arrows: false,
		draggable: true,
		swipe: true,
	});

	$('.review-slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		asNavFor: '.review-slider-nav',
		variableWidth: true,
		arrows: true,
		fade: false,
		dots: false,
		infinite: true,
		centerMode: true,
		responsive: [{

      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        infinite: true
      }

    }, {

      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      }

    }]
	});

	$('.review-slider-nav').slick({
		slidesToShow: 8,
		slidesToScroll: 1,
		asNavFor: '.review-slider',
		dots: false,
		arrows: false,
		centerMode: false,
		infinite: false,
		focusOnSelect: true
	});
});

const options = {
  gallery: '.review-slides .review-slider',
  children: '.slick-slide a',
	counter: false,
  pswpModule: () => import('photoswipe')
};
const lightbox = new PhotoSwipeLightbox(options);
lightbox.on('uiRegister', function() {
  lightbox.pswp.ui.registerElement({
    name: 'custom-caption',
    order: 9,
    isButton: false,
    appendTo: 'root',
    html: 'Caption text',
    onInit: (el, pswp) => {
      lightbox.pswp.on('change', () => {
        const currSlideElement = lightbox.pswp.currSlide.data.element;
        let captionHTML = '';
        if (currSlideElement) {
          const hiddenCaption = currSlideElement.querySelector('.hidden-caption-content');
          if (hiddenCaption) {
            // get caption from element with class hidden-caption-content
            captionHTML = hiddenCaption.innerHTML;
          } else {
            // get caption from alt attribute
            captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
          }
        }
        el.innerHTML = captionHTML || '';
      });
    }
  });
});
lightbox.init();

$(".single-project a[href^=\\#intro]").on("click", function(e) {
	e.preventDefault();
	var $this = $(this);
	var dest = $this.closest(".section");
	$("html,body").animate({
		scrollTop: $(dest).offset().top + $(dest)[0].getBoundingClientRect().height
	}, 300);
});

$(".single-story a[href^=\\#intro]").on("click", function(e) {
	e.preventDefault();
	var $this = $(this);
	var dest = $this.closest(".wp-block-group");
	$("html,body").animate({
		scrollTop: $(dest).offset().top + $(dest)[0].getBoundingClientRect().height
	}, 300);
});

$(".backtotop").on("click", function(e) {
	e.preventDefault();
	$("html,body").animate({
		scrollTop: 0
	}, 300);
});


// Resize event
$(window).on("resize.slickVideoPlayer", function() {
	resizePlayer(iframes, 16 / 9);
});

$(function() {

	let modal_links = [...document.querySelectorAll(".has-style-modal")];
	let links_modal_el = document.getElementById('links_modal');
	
	if(modal_links && links_modal_el) {

	let links_modal = bootstrap.Modal.getOrCreateInstance(links_modal_el, {
		keyboard: false,
		backdrop: true
	});


	[].forEach.call(modal_links, (link_modal, index, arr) => {

		let link = link_modal.querySelector("a");
		let iframe = links_modal_el.querySelector(".modal-body").querySelector("iframe");
		
		link.addEventListener("click", async (e) => {
			e.preventDefault();
			
			//iframe.src = e.currentTarget;
		
			links_modal.show();
		});
	})

}

})