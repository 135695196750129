(function ($) {
  const Forepoint = {
    common: {
      init() {
        require("./common");
      },
      finalize() {
        // Run everywhere. Loaded last.
        //console.log('boom');
      },
    },
    home: {
      init() {
        // Run on page with body class `home`.
        // require('./home');
      },
    },
    single: {
      init() {
        // require('./single');
      },
    },
    page: {
      init() {
        // require('./page');
        // require('./ajax');
      },
    },
    blog: {
      init() {
        require("./blog");
      },
    },
    contact: {
      init() {
        require("./contact");
      },
    },
  };

  var util = {
    fire(func, funcname, args) {
      const namespace = Forepoint;
      funcname = (funcname === undefined) ? "init" : funcname;
      if (func !== "" && namespace[func] && typeof namespace[func][funcname] === "function") {
        namespace[func][funcname](args);
      }
    },
    loadEvents() {
      util.fire("common");

      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), (i, classnm) => {
        util.fire(classnm);
      });

      util.fire("common", "finalize");
    },
  };

  $(document).ready(util.loadEvents);
}(jQuery));
